<template>
	<div class="body-bg">
		<div class="login-main">
			<div id="left">
				<img src="../../../assets/images/logo_03.png" class="logos">
				<div class="text">
					<p>中墅-墅智互联</p>
					<div>Zhongshu CRM System Management Terminal</div>
				</div>
			</div>
			<div id="right">
				<div class="title">欢迎登录</div>
				<el-form ref="login" :model="login" size="medium" :rules="loginRules" class="login-form"
					label-position="left">
					<el-form-item prop="loginName">
						<el-input v-model="login.loginName" type="text" placeholder="请输入账号" prefix-icon="el-icon-user"
							autocomplete="off" clearable />
					</el-form-item>
					<el-form-item prop="loginPass1">
						<el-input v-model="login.loginPass1" type="text" placeholder="请输入密码" prefix-icon="el-icon-lock"
							@keyup.enter.native="handleLogin" autocomplete="off" clearable show-password />
					</el-form-item>
				</el-form>
				<el-button class="login_btns_s aaa" :loading="loading" size="small" type="primary"
					style="width:100%;margin-top:20px" @click.native.prevent="handleLogin">
					<span v-if="!loading">登 录</span>
					<span v-else>登 录 中...</span>
				</el-button>
			</div>

		</div>
		<div class="support">中墅-墅智互联</div>
	</div>
</template>

<script>
	import jsSHA from "jssha"
	import {
		captch,
		info,
		permissionThirdListForLoginApi
	} from '@/api/admin/base'
	import {
		encrypt,
		decrypt
	} from '@/utils/jsencrypt'
	export default {
		data() {
			return {
				loading: false,
				login: {
					loginName: '',
					loginPass1: '',
					loginWay: 1,
				},
				loginRules: {
					loginName: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					loginPass1: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],

				},
				verifySrc: '',
				verify_status: true,
				remberMe: true,
				success_url: '',
				site_title: '',
			}
		},






		methods: {
			//  密码加密--sha512
			getPassword(password) {
				const shaObj = new jsSHA('SHA-512', 'TEXT', {
					encoding: "UTF8"
				})
				shaObj.update(password);
				var timestamp = new Date().getTime();
				const pas = shaObj.getHash("HEX") + timestamp;
				this.login.timestamp = timestamp
				const shaObj1 = new jsSHA('SHA-512', 'TEXT', {
					encoding: "UTF8"
				})
				shaObj1.update(pas);
				return shaObj1.getHash("HEX")
			},

			// 用户登录--账号密码
			handleLogin() {
				this.$refs['login'].validate(valid => {
					if (valid) {
						this.loading = true;
						this.login.loginPass = this.getPassword(this.login.loginPass1);
						this.$delete(this.login, 'loginPass1')
						this.$store.dispatch('login', this.login).then((res) => {
							console.log('ceccccccc', res)
							localStorage.setItem("setMenu_newMenu", '');
							localStorage.setItem("adminIdentity_index", 0);
							// 获取三级的数据权限
							permissionThirdListForLoginApi({}).then(res => {
								// let list = [{
								// 	"permissionId": "d3225f71c89acf2bbc49514e771f623d",
								// 	"permissionName": "store-add",
								// 	"permissionDesc": "新增",
								// 	"parentPermissionId": "0",
								// 	"level": 3,
								// }]
								localStorage.setItem("permissionThirdData", JSON.stringify(res
									.data));
								// console.info('localStorage.getItem("permissionThirdData"):'+localStorage.getItem("permissionThirdData"))
							})
							// localStorage.setItem("isLogin", '1');
							// 财务5、售后文员12、网签专员13、客诉11
							// if (localStorage.getItem('adminIdentity') == 11) {
							// 	this.$router.push('/admin/Complaint/index.html')
							// } else if (localStorage.getItem('adminIdentity') == 12) {
							// 	this.$router.push('/admin/Policy/index.html')
							// } else if (localStorage.getItem('adminIdentity') == 13) {
							// 	this.$router.push('/admin/netsign/index.html')
							// } else {
							// this.$router.push('/admin/Home/index.html')
							info({}).then(res => {

								localStorage.setItem("firstPageAddress", res.data[0]
									.permissionChildren[0]
									.permissionRoute);
								this.$router.push(res.data[0].permissionChildren[0]
									.permissionRoute)
							})
							// }

							this.loading = false
						}).catch(() => {
							this.loading = false
						})
					}
				})
			}
		},
	}
</script>
<style>
	.login_btns_s {
		background: #094693;
		border: none;
		height: 50px;
		width: 70% !important;
		border-radius: 25px;
		font-size: 20px;
		margin: 50px auto !important;
		display: block;
	}

	.logos {
		padding: 30px;
	}

	.login_btns_s:hover {
		background: #094693;
	}

	.el-button--primary.is-active,
	.el-button--primary:active {
		background: #094693;
	}

	#left {
		width: 50%;
		background: rgba(46, 119, 246, .2);
		height: 80vh;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		position: relative;
	}

	#left .text {
		position: absolute;
		bottom: 40px;
		right: 20px;
		text-align: right;
	}

	#left .text p {
		font-size: 30px;
		color: #fff;
		margin-bottom: 20px;
		font-weight: bold;
	}

	#left .text div {
		font-size: 26px;
		color: #fff;
	}

	.body-bg {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		overflow-y: auto;
		background: url(../../../assets/images/login.png) top center;
		align-items: center;
		background-size: cover;
	}

	.login-main {
		display: flex;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		height: 420px;

		border-radius: 8px;

		width: 80%;
		align-items: center;
		/*margin: 80px auto 0;*/
	}

	.login-main #right {
		width: 50%;
		height: 80vh;
		background-color: #FFF;
		margin: 0 auto;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		box-sizing: border-box;
		padding: 50px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}

	.login-main #right .el-form {
		width: 70%;
		margin: 0 auto;
	}

	.body-bg .el-input__inner {
		border: none;
		height: 60px;
		border-bottom: 1px solid #D9D9D9;
	}

	.site-title {
		font-weight: bold;
		font-size: 18px;
		text-align: center;
		color: #707070;
		margin-bottom: 20px;
		padding-top: 15px;
	}

	.el-image {
		display: block;
		margin: 0 auto 20px
	}

	.title {
		padding-bottom: 80px;
		font-size: 36px;
		color: #333333;
		text-align: center;
	}

	.support {
		font-size: 14px;
		color: #fff;
		text-align: center;
		padding-top: 40px;
		font-family: PingFang SC;
		opacity: 0.6;
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		bottom: 5px
	}
</style>